function About() {
    return (
        <div className="about mt-20 px-[20px] py-10 2xl:px-[250px] xl:px-[200px] lg:px-[120px] md:px-[80px] sm:px-[40px] h-[100vh]">
            <p className="text-neutral-100 text-lg">This website is used to display my works as a developer and give people the option to contact me. This website doesn't use any cookies, if you use the contact formular your data will be stored for up to 90 days with <a className="underline" href="https://formspark.io">formspark.io</a>.</p>
            <br />
            <p className="text-neutral-100 text-lg">You can check they way formspark stores data <a className="underline" href="https://formspark.io/legal/privacy-policy/">here</a>.</p>
            <br />
            <p className="text-neutral-100 text-lg">If you like me to delete your contact form submission earlier, please send me a message:</p>
            <br />
            <a className="text-neutral-100 underline text-lg" href="mailto:niklasbeckerr@gmail.com">Email</a>
        </div>
    )
}

export default About;