import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';


function Header() {
    return (
        <header className="w-screen flex font-elegant text-2xl justify-end items-center sm:px-10 h-10">
            <div className="text-md flex text-neutral-100">
                <Link className="mr-4" to="/">Home</Link>
                <HashLink className="mr-4" to="/#contact">Contact</HashLink>
                <Link className="mr-4" to="/about">About</Link>
            </div>
        </header>
    )
}

export default Header;