import './App.css';
import Contact from './components/Contact';
import Header from './components/Header';
import {
  Routes,
  Route,
} from "react-router-dom";
import About from './components/About';
import { useState, useEffect } from "react";

function App() {

  const projectColors = {
    default: "bg-gray-800",
    emerald: "bg-emerald-900",
    blue: "bg-blue-900",
    zinc: "bg-zinc-900"
  };
  const [projectColor, setProjectColor] = useState("default")
  const projectDefaultClass = "work-box hover:scale-[102%] group bg-cover bg-slate-200 h-full rounded-sm drop-shadow-xl relative safari";
  const projects = [
    { link: "https://corratio.de", title: "Corratio Website", classes: projectDefaultClass + " bg-corratio" },
    { link: "https://betterprogramming.pub/how-to-create-a-chrome-extension-with-react-in-2020-5dadebf2135e", title: "How to create a React Chrome Extension", classes: projectDefaultClass + " bg-white-abstract" },
    { link: "https://www.beitzinger-consulting.de/", title: "Beitzinger Consulting", classes: projectDefaultClass + " bg-consulting" }
  ];

  const createClass = (customClass, color = projectColor) => {
    return customClass + " " + projectColors[color];
  };

  const selectColor = (color) => {
    if (projectColor === color) {
      setProjectColor("default");
    } else {
      setProjectColor(color);
    }
    setScrollbarColor();
  };

  const setScrollbarColor = () => {
    const colorCodes = {
      zinc: "rgb(24, 24, 27)",
      blue: "rgb(30, 58, 138)",
      emerald: "rgb(6, 78, 59)",
      default: "#1f2937",
    }
    const root = document.querySelector(":root");
    root.style.setProperty("--scrollbar-color", colorCodes[projectColor]);
  };

  useEffect(() => {
    const color = localStorage.getItem("color");
    if (color) {
      setProjectColor(color);
    }
  }, []);


  useEffect(() => {
    setScrollbarColor();
    localStorage.setItem("color", projectColor);
  }, [projectColor]);

  return (
    <div className={`${createClass("App w-screen")}${" scrollbar-" + projectColor}`}>
      <Header />
      <Routes>
        <Route path="/" element={
          <div id="home" className="px-[20px] py-10 2xl:px-[250px] xl:px-[200px] lg:px-[120px] md:px-[80px] sm:px-[40px]">
            <div className="intro-box mt-20 mb-40">
              <h5 className="font-elegant text-orange-300 text-4xl mb-[-5px] ml-1">MY NAME IS NICK</h5>
              <h2 className="text-7xl font-pensum text-neutral-100 mb-10">I create websites</h2>
              <p className=" text-neutral-100 text-2xl">I am a web developer based in Leipzig Germany, coding all things <br /><span className="underline">Vue, React and TypeScript.</span></p>
              <div className="mt-4 flex">
                {Object.keys(projectColors).map(color => {
                  return (<div key={color + "-selector"} onClick={() => selectColor(color)} className={createClass(`cursor-pointer w-6 h-6 rounded-full mr-2 ${color === projectColor ? " border-2" : ""}`, color)} />)
                })}
              </div>
            </div>
            <div className="my-work w-100">
              <h3 className="text-neutral-100 font-pensum text-3xl">Some of my work</h3>
              <div className="work-previews w-100 h-[300px] md:h-[400px] grid grid-rows-3 sm:grid-rows-1 grid-flow-col gap-6 lg:gap-8 xl:gap-16 pt-10">
                {projects.map((project, i) => {
                  return (
                    <a className={project.classes} rel="noreferrer" href={project.link} target="_blank" key={project.link + i}>
                      <span className="absolute group-hover:opacity-1 sm:group-hover:opacity-0 text-neutral-100 bg-gray-800 bg-opacity-[25%] w-full h-full font-pensum text-xl p-2">{project.title}</span>
                    </a>
                  )
                })}
              </div>
            </div>
            <div className="contact w-100 my-20">
              <div id="contact" className="h-full">
                <div className="max-w-2xl mx-auto bg-slate-500 shadow-lg rounded-lg">
                  <div className="px-6 py-5">
                    <div className="flex items-start">
                      <div className="fill-current flex-shrink-0 mr-5">
                        {/* <img alt="memoji of niklas" width="40px" className="rounded-sm" /> */}
                      </div>
                      <div className="flex-grow">
                        <div className="w-full sm:flex justify-between items-center mb-3">
                          <p className="text-2xl font-pensum leading-snug font-semibold text-gray-50 mb-1 sm:mb-0">Interested in working with me?</p>
                        </div>
                        <div className="flex items-end justify-between whitespace-normal">
                          <div className="max-w-md text-indigo-100">
                            <p className="mb-3">Contact me with the form below and I will reach out to you as soon as possible. You can also just send me an email directly if you prefer:</p>
                            <a className="mb-2 underline font-semibold" href="mailto:niklasbeckerr@gmail.com">Email</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Contact />
            </div>
          </div>
        }>

        </Route>
        <Route path="about" element={<About />} />
      </Routes>
    </div>
  );
}

export default App;
