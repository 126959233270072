import { useState } from "react";
import { Link } from "react-router-dom";

function Contact() {

    const defaultFormValues = {
        firstName: "",
        lastName: "",
        email: "",
        message: "",
    };


    const [termsAccepted, setTermsAccepted] = useState(false);
    const [contactFormFields, setContactFormFields] = useState({
        ...defaultFormValues
    });

    const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
    const [formSubmitError, setFormSubmitError] = useState(false);

    const generateAlertClasses = (success) => {
        const baseClasses = "fly-in border-l-4 p-4 fixed bottom-10 left-0 "
        if (success) {
            return baseClasses + "bg-green-200 border-green-600 text-green-600";
        } else {
            return baseClasses + "bg-red-200 border-red-600 text-red-600";
        }
    }

    const checkboxHandler = () => {
        setTermsAccepted(!termsAccepted);
    }

    const sendForm = (e) => {
        e.preventDefault();

        if (Object.values(contactFormFields).some(val => val === "")) {
            setFormSubmitError(true);
            setTimeout(() => {
                setFormSubmitError(false);
            }, 5000);

            return;
        }

        fetch("https://submit-form.com/CYThJvUp", {
            method: "POST",
            body: JSON.stringify({
                name: contactFormFields.firstName + " " + contactFormFields.lastName,
                email: contactFormFields.email,
                message: contactFormFields.message,
            }),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                setFormSubmitSuccess(true);
                setTimeout(() => {
                    setFormSubmitSuccess(false);
                }, 5000);
                setContactFormFields({ ...defaultFormValues })
            }
        });
    }

    return (
        <div className="contact-form max-w-screen-md mx-auto p-5 mt-10">
            {formSubmitSuccess || formSubmitError ?
                <div className={generateAlertClasses(formSubmitSuccess)} role="alert">
                    <p className="font-bold">
                        {formSubmitSuccess ? "Success" : "Error"}
                    </p>
                    <p>
                        {formSubmitSuccess ? "Your message has been sent." : "Message couldn't be send! Please check if you filled the form correctly."}
                    </p>
                </div>
                : null}

            <div className="text-center mb-16">
                <h3 className="text-3xl sm:text-4xl leading-normal font-semibold tracking-tight text-neutral-100">
                    Get In Touch
                </h3>
            </div>

            <form className="w-full">
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-neutral-100 text-xs font-bold mb-2" htmlFor="grid-first-name">
                            First Name
                        </label>
                        <input value={contactFormFields.firstName} onChange={(e) => setContactFormFields({ ...contactFormFields, firstName: e.target.value })} className="appearance-none block w-full bg-gray-200 text-gray-800 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-neutral-100 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Last Name
                        </label>
                        <input value={contactFormFields.lastName} onChange={(e) => setContactFormFields({ ...contactFormFields, lastName: e.target.value })} className="appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Doe" />
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-neutral-100 text-xs font-bold mb-2" htmlFor="grid-email">
                            Email Address
                        </label>
                        <input value={contactFormFields.email} onChange={(e) => setContactFormFields({ ...contactFormFields, email: e.target.value })} className="appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="email" placeholder="********@*****.**" />
                    </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-neutral-100 text-xs font-bold mb-2" htmlFor="grid-message">
                            Your Message
                        </label>
                        <textarea id="grid-message" value={contactFormFields.message} onChange={(e) => setContactFormFields({ ...contactFormFields, message: e.target.value })} rows="10" className="appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                    </div>
                    <div className="flex justify-between w-full px-3">
                        <div className="md:flex md:items-center"><div className="form-check">
                            <input onChange={checkboxHandler} className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm checked:bg-emerald-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" checked={termsAccepted} id="flexCheckDefault" />
                            <label className="form-check-label inline-block text-neutral-100" htmlFor="flexCheckDefault">
                                I have read and accepted the <br className="md:hidden" /><Link className="underline" to="/about">terms {'&'} conditions</Link>
                            </label>
                        </div></div>
                        <button onClick={sendForm} disabled={!termsAccepted} className="shadow bg-emerald-700 disabled:bg-gray-500 hover:bg-emerald-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded" type="submit">
                            Send Message
                        </button>
                    </div>

                </div>

            </form>
        </div>
    )
}

export default Contact;